






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue, { VueConstructor } from 'vue'
import { extend, ValidationObserver } from 'vee-validate'
import Product from '../../../../store/models/Product'
import { InterestCalculTypeReadable } from '../../../../store/models/Product'
import Multiselect from 'vue-select'
import Category from '../../../../store/models/Category'
import Organization from '../../../../store/models/Organization'
import api from '@/api'

interface ComponentWithValidator extends Vue {
  product: any
  categorie: any
  manageFinancialFlow: string
  productNumber: string
  typeFinance: any
  description: string
  fraisRetrais: string
  differeBeneficiaire: string
  differeSfd: string
  durreDiffereBeneficiaireMin: number
  durreDiffereBeneficiaireMax: number
  durreDiffereSfdMin: number
  durreDiffereSfdMax: number
  graceDelaySfdMin: number
  graceDelaySfdMax: number
  graceDelaySfd: string
  montMin: number
  montMax: number
  /* montMinSfd: number
  montMaxSfd: number */
  dureeMin: number
  dureeMax: number
  sfdLoanMinDuration: number
  sfdLoanMaxDuration: number
  mtnFees: number
  moovFees: number
  periodeRemBenef: number
  periodeRemSfd: number
  beneficiaryInterestCalculMode: string
  sfdInterestCalculMode: string
  //deferredMode: string
  interestRateBeneficiary: number
  interestRateSfd: number
  name: string
  organizationsChoices: any
  optionsBeneficiarie: Array<{}>
  optionsTypeFinance: Array<{}>
  actuel: number
  nbOfBeneficiaryPerGroup: Array<{ number: string; index: string }>
  organisations: Array<{}>
  valueSfd: []
  optionSfd: Array<{}>
  InterestCalculTypeReadable: any
  step1IsValid: boolean
  step2IsValid: boolean
  step3IsValid: boolean
  mtnDefaultOvaPhone: string
  mtnDefaultOvaUsername: string
  mtnDefaultOvaPwd: string
  mtnRefundOvaPhone: string
  mtnRefundOvaUsername: string
  mtnRefundOvaPwd: string
  moovDefaultOvaPhone: string
  moovDefaultOvaUsername: string
  moovDefaultOvaPwd: string
  moovRefundOvaPhone: string
  moovRefundOvaUsername: string
  moovRefundOvaPwd: string
  $refs: {
    validator4: InstanceType<typeof ValidationObserver>
    validator3: InstanceType<typeof ValidationObserver>
    validator1: InstanceType<typeof ValidationObserver>
    validator2: InstanceType<typeof ValidationObserver>
  }
}
export default (Vue as VueConstructor<ComponentWithValidator>).extend({
  computed: {
    getActuel(): number {
      return this.actuel
    },
    productList(): Array<Product> {
      return Product.all()
    },
    categories(): Array<Category> {
      return Category.all()
    },
    organizations(): Array<Organization> {
      return Organization.query()
        .where('type', 'sfd')
        .orderBy((organization: any) => organization['name'].replace(/ /g, ''))
        .get()
    },
    verifyBeneficiaryMaxAmount(): { numeric: boolean; minValue: number } {
      return {
        numeric: true,
        minValue: this.montMin
      }
    },
    verifySfdInterest() {
      return {
        maxValue: this.interestRateBeneficiary
      }
    },
    /* verifySfdMaxAmount(): { numeric: boolean; minValue: number } {
      return {
        numeric: true,
        minValue: this.montMinSfd
      }
    }, */
    verifyOvaNumber() {
      return {
        numeric: true,
        length: 11
      }
    },
    verifySfdLoanMaxDuration(): { numeric: boolean; minValue: number } {
      return {
        numeric: true,
        minValue: this.sfdLoanMinDuration
      }
    },
    verifyBeneficiaryLoanMaxDuration(): { numeric: boolean; minValue: number } {
      return {
        numeric: true,
        minValue: this.dureeMin
      }
    },
    verifyDurreDiffereSfdMax() {
      return {
        numeric: true,
        minValue: this.durreDiffereSfdMin
      }
    },

    verifyGraceDelaySfdMax() {
      return {
        numeric: true,
        minValue: this.graceDelaySfdMin
      }
    },

    verifyDurreDiffereBeneficiaireMax() {
      return {
        numeric: true,
        minValue: this.durreDiffereBeneficiaireMin
      }
    }
  },

  watch: {},

  data() {
    return {
      showValidateAlert: false,
      showErrorCreation: false,
      validateActionLoading: false,
      step1IsValid: false,
      step2IsValid: false,
      step3IsValid: false,
      dangerAlertMessage: '',
      successAlertMessage: '',
      manageFinancialFlow: '',
      product: {
        type: Product
      },
      categorie: {
        type: Category
      },
      productNumber: '',
      typeFinance: [],
      description: '',
      fraisRetrais: '',
      differeBeneficiaire: 'oui',
      differeSfd: 'oui',
      durreDiffereBeneficiaireMin: '',
      durreDiffereBeneficiaireMax: '',
      durreDiffereSfdMin: '',
      durreDiffereSfdMax: '',
      graceDelaySfdMin: '',
      graceDelaySfdMax: '',
      graceDelaySfd: 'oui',
      montMin: '',
      montMax: '',
      /* montMinSfd: 0,
      montMaxSfd: 0, */
      dureeMin: 0,
      dureeMax: 0,
      sfdLoanMinDuration: '',
      sfdLoanMaxDuration: '',
      periodeRemBenef: '',
      periodeRemSfd: '',
      beneficiaryInterestCalculMode: '',
      sfdInterestCalculMode: '',
      //deferredMode: '',
      interestRateBeneficiary: '',
      interestRateSfd: '',
      mtnFees: '',
      moovFees: '',
      name: '',
      organizationsChoices: [],
      actuel: 1,
      nbOfBeneficiaryPerGroup: [],
      organisations: [],
      valueSfd: [],
      mtnDefaultOvaPhone: '',
      mtnDefaultOvaUsername: '',
      mtnDefaultOvaPwd: '',
      mtnRefundOvaPhone: '',
      mtnRefundOvaUsername: '',
      mtnRefundOvaPwd: '',
      moovDefaultOvaPhone: '',
      moovDefaultOvaUsername: '',
      moovDefaultOvaPwd: '',
      moovRefundOvaPhone: '',
      moovRefundOvaUsername: '',
      moovRefundOvaPwd: '',
      optionSfd: [
        {
          name: 'Tous',
          index: '1'
        },
        {
          name: 'sfs',
          index: '2'
        },
        {
          name: 'sf',
          index: '3'
        }
      ],
      optionsBeneficiarie: [
        {
          number: '01',
          index: '1'
        },
        {
          number: '02',
          index: '2'
        },
        {
          number: '03',
          index: '3'
        },
        {
          number: '04',
          index: '4'
        },
        {
          number: '05',
          index: '5'
        },
        {
          number: '06',
          index: '6'
        },
        {
          number: '07',
          index: '7'
        },
        {
          number: '08',
          index: '8'
        },
        {
          number: '09',
          index: '9'
        }
      ],
      optionsTypeFinance: [
        {
          type: 'externe',
          index: '1'
        },
        {
          type: 'interne',
          index: '2'
        },
        {
          type: 'hors',
          index: '3'
        }
      ],
      InterestCalculTypeReadable: InterestCalculTypeReadable
    }
  },
  components: {
    Multiselect
  },

  methods: {
    getBool(value: string): boolean {
      if (value == 'oui') return true
      return false
    },
    updateProduct(): void {
      this.$refs.validator4.validate().then((isValid: boolean) => {
        const creditDetails: any = {
          beneficiaryMinAmount: this.montMin,
          beneficiaryMaxAmount: this.montMax,
          beneficiaryLoanMinDuration: this.dureeMin,
          beneficiaryLoanMaxDuration: this.dureeMax,
          sfdLoanMinDuration: this.sfdLoanMinDuration,
          sfdLoanMaxDuration: this.sfdLoanMaxDuration,
          beneficiaryInterestCalculMode: this.beneficiaryInterestCalculMode,
          sfdInterestCalculMode: this.sfdInterestCalculMode,
          nbOfBeneficiaryPerGroup: this.nbOfBeneficiaryPerGroup.map(
            (value: any) => parseInt(value.number)
          ),
          allowWithdrawFees: this.fraisRetrais == 'oui' ? true : false
        }

        if (creditDetails.allowWithdrawFees) {
          if (this.mtnFees > 0) creditDetails.mtnFees = this.mtnFees
          if (this.moovFees > 0) creditDetails.moovFees = this.moovFees
        }

        const reimbursementDetails: any = {
          allowDeferredPaymentForBeneficiary: this.getBool(
            this.differeBeneficiaire
          ),
          allowDeferredPaymentForSfd: this.getBool(this.differeSfd),
          allowGraceDelayForSfd: this.getBool(this.graceDelaySfd),
          beneficiaryPaymentPeriodicity: this.periodeRemBenef,
          sfdPaymentPeriodicity: this.periodeRemSfd,
          beneficiaryInterestRate: this.interestRateBeneficiary,
          sfdInterestRate: this.interestRateSfd
        }

        if (reimbursementDetails.allowDeferredPaymentForBeneficiary) {
          reimbursementDetails.beneficiaryDeferredPaymentMinDuration = this.durreDiffereBeneficiaireMin
          reimbursementDetails.beneficiaryDeferredPaymentMaxDuration = this.durreDiffereBeneficiaireMax
        }
        if (reimbursementDetails.allowDeferredPaymentForSfd) {
          reimbursementDetails.sfdDeferredPaymentMinDuration = this.durreDiffereSfdMin
          reimbursementDetails.sfdDeferredPaymentMaxDuration = this.durreDiffereSfdMax
        }
        if (reimbursementDetails.allowGraceDelayForSfd) {
          reimbursementDetails.sfdGraceDelayMinDuration = this.graceDelaySfdMin
          reimbursementDetails.sfdGraceDelayMaxDuration = this.graceDelaySfdMax
        }

        const trData: any = {
          name: this.name,
          productNumber: this.productNumber,
          description: this.description,
          manageFinancialFlow: this.getBool(this.manageFinancialFlow),
          financementType: this.typeFinance.map((value: any) => {
            return value.type
          }),
          sfdConcern: this.organizationsChoices.map((value: any) => {
            return value.id
          }),
          categoryId: this.categorie,
          creditDetails,
          reimbursementDetails
        }
        if (trData.manageFinancialFlow) {
          trData.defaultOvaCredentials = [
            {
              otm: 'mtn',
              phoneNumber: this.mtnDefaultOvaPhone,
              username: this.mtnDefaultOvaUsername,
              password: this.mtnDefaultOvaPwd
            },
            {
              otm: 'moov',
              phoneNumber: this.moovDefaultOvaPhone,
              username: this.moovDefaultOvaUsername,
              password: this.moovDefaultOvaPwd
            }
          ]
          trData.refundOvaCredentials = [
            {
              otm: 'mtn',
              phoneNumber: this.mtnRefundOvaPhone,
              username: this.mtnRefundOvaUsername,
              password: this.mtnRefundOvaPwd
            },
            {
              otm: 'moov',
              phoneNumber: this.moovRefundOvaPhone,
              username: this.moovRefundOvaUsername,
              password: this.moovRefundOvaPwd
            }
          ]
        }

        if (isValid) {
          if (this.showValidateAlert == false) {
            this.showValidateAlert = true
            return
          }
          this.validateActionLoading = true

          console.log('Organization choice', this.organizationsChoices)
          Product.api()
            .updateOne(this.product.id, trData)
            .then((response: any) => {
              if (response.response.status == 200) {
                this.$router.push({
                  name: 'product',
                  params: {
                    successAlertMessage:
                      'Le produit ' +
                      this.name +
                      ' a été mis à jour avec succès'
                  }
                })
              }
            })
            .catch((error: any) => {
              this.validateActionLoading = false
              this.showValidateAlert = false
              console.log('this is error', error.response.data)
              if (error.response.data.error == 'existing creditline') {
                this.$router.push({
                  name: 'product',
                  params: {
                    dangerAlertMessage:
                      'Impossible de modifier le produit ' +
                      this.name +
                      '. Il a été utilisé dans des dispatchings'
                  }
                })
              } else {
                this.showErrorCreation = true
              }
            })
        }
      })
    },
    gotoForth(): void {
      this.$refs.validator3.validate().then((isValid: boolean) => {
        if (isValid) {
          this.actuel = 4
          this.step3IsValid = true
        } else this.step3IsValid = false
      })
    },
    gotoThird(): void {
      this.$refs.validator2.validate().then((isValid: boolean) => {
        if (isValid) {
          this.actuel = 3
          this.step2IsValid = true
        } else this.step2IsValid = false
      })
    },
    gotoSecond(): void {
      this.$refs.validator1.validate().then((isValid: boolean) => {
        if (isValid) {
          this.actuel = 2
          this.step1IsValid = true
        } else {
          this.step1IsValid = false
        }
      })
    },
    toggle(index: any): void {
      if (index == 2 && this.step1IsValid) {
        this.actuel = index
      } else if (index == 3 && this.step1IsValid && this.step2IsValid) {
        this.actuel = index
      } else if (
        index == 4 &&
        this.step1IsValid &&
        this.step2IsValid &&
        this.step3IsValid
      )
        this.actuel = index
      else if (index == 1) this.actuel = 1
    }
    // addTag(newTag :any) : void {
    //   const tag = {
    //     name: newTag,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    //   }
    //   this.optionsBeneficiarie.push(tag)
    //   this.value.push(tag)
    // },
    // addTagSfd(newTag : any): any{
    //   const tag = {
    //     name: newTag,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    //   }
    //   this.optionSfd.push(tag)
    //   this.valueSfd.push(tag)
    // },
  },

  async created(this: any): Promise<any> {
    await Category.api().fetchAll()
    await Organization.api().fetch()

    this.product = Product.query()
      .with('category')
      .find(this.$route.params.productId)
    if (!this.product) {
      await Product.api().fetchAll()
      this.product = Product.query()
        .with('category')
        .find(this.$route.params.productId)
    }
    try {
      const ovas = await api.get(`/products/${this.product.id}/ovas`)
      console.log({ ovas })
      const defaultOvas = ovas.data.defaultOvas
      const refundOvas = ovas.data.refundOvas
      defaultOvas.map((ova: any) => {
        if (ova.provider === 'mtn-benin') {
          this.mtnDefaultOvaPhone = ova.msisdn
          this.mtnDefaultOvaUsername = ova.ovaParams[0].value
          this.mtnDefaultOvaPwd = ova.ovaParams[1].value
        } else if (ova.provider === 'moov-benin') {
          this.moovDefaultOvaPhone = ova.msisdn
          this.moovDefaultOvaUsername = ova.ovaParams[0].value
          this.moovDefaultOvaPwd = ova.ovaParams[1].value
        }
      })
      refundOvas.map((ova: any) => {
        if (ova.provider === 'mtn-benin') {
          this.mtnRefundOvaPhone = ova.msisdn
          this.mtnRefundOvaUsername = ova.ovaParams[0].value
          this.mtnRefundOvaPwd = ova.ovaParams[1].value
        } else if (ova.provider === 'moov-benin') {
          this.moovRefundOvaPhone = ova.msisdn
          this.moovRefundOvaUsername = ova.ovaParams[0].value
          this.moovRefundOvaPwd = ova.ovaParams[1].value
        }
      })
    } catch (error) {
      console.log({ error })
    }

    /* if (ovas.defaultOvas) {
    } */
    console.log('this is product', this.product)
    ;(this.name = this.product.name),
      (this.categorie = this.product.category.id),
      (this.productNumber = this.product.productNumber),
      (this.manageFinancialFlow = this.product.manageFinancialFlow
        ? 'oui'
        : 'non'),
      (this.typeFinance = this.product.financementType.map(
        (value: any, index: any) => {
          return {
            type: value,
            index: index + 1
          }
        }
      )),
      (this.description = this.product.description),
      (this.differeBeneficiaire = this.product.reimbursementDetails
        .allowDeferredPaymentForBeneficiary
        ? 'oui'
        : 'non'),
      (this.differeSfd = this.product.reimbursementDetails
        .allowDeferredPaymentForSfd
        ? 'oui'
        : 'non'),
      (this.graceDelaySfd = this.product.reimbursementDetails
        .allowGraceDelayForSfd
        ? 'oui'
        : 'non'),
      (this.durreDiffereBeneficiaireMin = this.product.reimbursementDetails
        .beneficiaryDeferredPaymentMinDuration as any),
      (this.durreDiffereBeneficiaireMax = this.product.reimbursementDetails
        .beneficiaryDeferredPaymentMaxDuration as any),
      (this.durreDiffereSfdMin = this.product.reimbursementDetails
        .sfdDeferredPaymentMinDuration as any),
      (this.durreDiffereSfdMax = this.product.reimbursementDetails
        .sfdDeferredPaymentMaxDuration as any),
      (this.graceDelaySfdMin = this.product.reimbursementDetails
        .sfdGraceDelayMinDuration as any),
      (this.graceDelaySfdMax = this.product.reimbursementDetails
        .sfdGraceDelayMaxDuration as any),
      (this.montMin = this.product.creditDetails.beneficiaryMinAmount),
      (this.montMax = this.product.creditDetails.beneficiaryMaxAmount),
      /* (this.montMinSfd = this.product.creditDetails.sfdMinAmount),
      (this.montMaxSfd = this.product.creditDetails.sfdMaxAmount), */
      (this.dureeMin = this.product.creditDetails.beneficiaryLoanMinDuration),
      (this.dureeMax = this.product.creditDetails.beneficiaryLoanMaxDuration),
      (this.sfdLoanMinDuration = this.product.creditDetails.sfdLoanMinDuration),
      (this.sfdLoanMaxDuration = this.product.creditDetails.sfdLoanMaxDuration),
      (this.periodeRemBenef = this.product.reimbursementDetails.beneficiaryPaymentPeriodicity),
      (this.periodeRemSfd = this.product.reimbursementDetails.sfdPaymentPeriodicity),
      (this.interestRateBeneficiary = this.product.reimbursementDetails.beneficiaryInterestRate),
      (this.interestRateSfd = this.product.reimbursementDetails.sfdInterestRate),
      (this.beneficiaryInterestCalculMode = this.product.creditDetails.beneficiaryInterestCalculMode),
      (this.sfdInterestCalculMode = this.product.creditDetails.sfdInterestCalculMode),
      /* (this.deferredMode = this.product.creditDetails.deferredMode), */
      (this.organizationsChoices = Organization.findIn(this.product.sfdConcern))
    this.fraisRetrais = this.product.creditDetails.allowWithdrawFees
      ? 'oui'
      : 'non'
    this.mtnFees = this.product.creditDetails.mtnFees
    this.moovFees = this.product.creditDetails.moovFees
    ;(this.nbOfBeneficiaryPerGroup = this.product.creditDetails.nbOfBeneficiaryPerGroup.map(
      (value: any) => {
        return {
          index: value,
          number: `0${value.toString()}`
        }
      }
    )),
      extend('uniqueProductCode', {
        message: () => 'Ce numéro de produit est déjà utilisé',
        validate: value => {
          const products = Product.query()
            .where(
              (val: any) =>
                val.productNumber == value && val.id != this.product.id
            )
            .get()
          if (products.length != 0) return false
          return true
        }
      })
    extend('uniqueProductName', {
      message: () => 'Ce nom est déjà utilisé',
      validate: value => {
        const products = Product.query()
          .where((val: any) => val.name == value && val.id != this.product.id)
          .get()
        if (products.length != 0) return false
        return true
      }
    })
  }
})
